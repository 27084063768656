<template>
  <div class="px-6">
    <Notify></Notify>
    <v-row v-if="works.length > 0">
      <v-col cols="12" md="6" v-for="(work, index) in works" :key="work.id">
        <v-card class="card-shadow mt-6 border-radius-xl pa-4">
          <div class="">
            <div class="d-flex justify-space-between mb-3">
              <h5 class="text-h6 text-typo font-weight-bold mb-6 mt-3">
                {{
                  $t("project.assign_employee") +
                  " " +
                  $t("project.for time") +
                  " # " +
                  work.data.name
                }}
              </h5>
              <div class="mt-4">
                <span class="text-sm text-typo">
                  <v-icon size="25"> mdi-account-group-outline </v-icon>
                </span>
                <span class="text-sm mx-2 text-typo">{{
                  work.data.count_employee
                }}</span>
              </div>
            </div>
            <div class="d-flex justify-space-between">
              <div class="text-xs text-body">
                {{
                  $t("project.start_time_permanence") +
                  " :  " +
                  work.data.start_time
                }}
              </div>
              <div class="text-xs text-body">
                {{
                  $t("project.end_time_permanence") +
                  " :  " +
                  work.data.end_time
                }}
              </div>
            </div>
            <hr class="horizontal dark my-4" />
            <v-row>
              <v-col cols="10">
                <Input :item="style_form[index]"></Input>
              </v-col>
              <v-col cols="2" class="d-flex align-end justify-center">
                <div class="mb-7">
                  <Button
                    @click="assign_employee(work, index)"
                    :loader="work.loading"
                  ></Button>
                </div>
              </v-col>
            </v-row>

            <div
              class="category-card mt-7"
              v-if="work.data.employee.length > 0"
            >
              <h5 class="text-md text-body font-weight-bold mt-6 title">
                {{ $t("employees") }}
              </h5>
              <!-- <div v-for="item in work.data.employee" :key="item.id">
                <h5 class="text-xs text-typo">{{ item.full_name }}</h5>
              </div> -->
              <v-row>
                <v-col
                  cols="6"
                  md="4"
                  v-for="item in work.data.employee"
                  :key="item.id"
                >
                  <h5 class="text-xs mx-5 text-typo">{{ item.full_name }}</h5>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Input from "../Components/Input";
import Notify from "../Components/Notify";
import Button from "../Components/Button";
import projectService from "../../../services/project.service";
import { style_assign } from "./style";
export default {
  components: {
    Input,
    Button,
    Notify,
  },
  data() {
    return {
      works: [],
      id: null,
      valid: true,
      loading: [],
      style_form: [],
      index: 0,
    };
  },

  methods: {
    assign_employee(item, index) {
      console.log(item);
      if (this.style_form[index].value.length == 0) {
        this.$store.commit("form/SET_NOTIFY", {
          msg: this.$i18n.t("please select employee"),
          type: "Danger",
        });
        return;
      }
      // var req_count =
      //   parseInt(item.data.count_employee) -
      //   parseInt(item.data.employee.length);
      // console.log(req_count);
      // if (this.style_form[index].value.length > req_count) {
      //   this.$store.commit("form/SET_NOTIFY", {
      //     msg: this.$i18n.t(
      //       "The number of users selected is greater than the required number please check"
      //     ),
      //     type: "Danger",
      //   });
      //   return;
      // }
      item.loading = true;
      return projectService
        .assign_employee(this.id, {
          worker_count_and_times_id: item.data.id,
          employee_id: this.style_form[index].value,
        })
        .then(
          (response) => {
            item.loading = false;
            item.data.employee = response.data.data.employee;
            this.$store.commit("form/SET_NOTIFY", {
              msg: response.data.message,
              type: "Success",
            });
          },
          (error) => {
            item.loading = false;
            console.log(error);
            this.$store.commit("form/SET_NOTIFY", {
              msg: error.response.data.message,
              type: "Danger",
            });
          }
        );
    },
    get_data() {
      return projectService.get_works_employee(this.id).then(
        (response) => {
          for (let i = 0; i < response.data.data.length; i++) {
            var obj = {
              loading: false,
              data: response.data.data[i],
            };
            this.works.push(obj);
            this.style_form[i] = style_assign;
            this.style_form[i].items = response.data.employee;
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("form/SET_NOTIFY", {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          });
          // this.$router.push({
          //   path: "/projects",
          //   params: { message: message },
          // });
        }
      );
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.get_data();
    document.title = this.$i18n.t("AssignEmployee");
  },
};
</script>
<style scoped>
.category-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  padding-top: 26px;
  padding-bottom: 20px;
  position: relative;
}
.category-card .title {
  position: absolute;
  top: -37px;
  background: white;
  min-width: max-content;
  padding-left: 7px;
  padding-right: 7px;
  display: inline-block;
}
</style>