import i18n from '@/i18n.js'
export const style_work =
    [

        {
            col: "6",
            type: "text",
            label: i18n.t("name"),
            placeholder: "صباحي",
            error: null,
            value_text: "name",
            value: "",
            rules: [(v) => !!v || i18n.t("form.Item is required")],
        },
        {
            col: "6",
            type: "number",
            label: i18n.t("project.count_employee"),
            placeholder: "5",
            error: null,
            value_text: "count_employee",
            value: "",
            rules: [(v) => !!v || i18n.t("form.Item is required")],
        },

        {
            col: "6",
            type: "time",
            label: i18n.t("project.start_time_permanence"),
            error: null,
            value_text: "start_time",
            value: "",
            rules: [(v) => !!v || i18n.t("form.Item is required")],
        },

        {
            col: "6",
            type: "time",
            label: i18n.t("project.end_time_permanence"),
            error: null,
            value_text: "end_time",
            value: "",
            rules: [(v) => !!v || i18n.t("form.Item is required")],
        },
    ];

export const style_assign =
{
    col: "12",
    type: "autocomplete",
    label: i18n.t("employee"),
    value_text: "employee_id",
    items: [],
    value: [],
    title_select: "full_name",
    type_select: "multiple",
    error: null,
}

