<template>
  <div :class="class_div">
    <v-btn
      :loading="loader"
      class="font-weight-bold text-xs btn-default mb-0"
      :class="classes"
      color="white"
      text
      :link="link ? true : false"
      :to="link"
      @click="$emit('click')"
      :type="type"
    >
      <span slot="loader">
        <v-progress-circular
          style="width: 20px; height: 20px"
          indeterminate
          color="white"
        ></v-progress-circular>
      </span>
      <slot name="body">
        <template v-if="title">
          {{ title }}
        </template>
        <template v-else>
          {{ $t("form.save") }}
        </template>
      </slot>
    </v-btn>
  </div>
</template>
<script>
export default {
  props: {
    loader: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
    title: {
      type: String,
    },
    class_div: {
      type: String,
      default: "",
    },
    classes: {
      type: String,
      default: "bg-gradient-default py-5 px-6",
    },
    link: {
      type: String,
    },
  },
  name: "Button",
  data() {
    return {};
  },
  updateChangeFile: function (value) {
    this.$emit("click", value);
  },
};
</script>